@use "../../styles/constants.scss";

.footer {
  position: relative;
  padding-inline: calc(var(--size) * 2.4);
  padding-bottom: var(--size);
  @media only screen and (max-width: 1000px) {
    padding-inline: calc(var(--size) * 1);
  }
  &Top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--size);
    padding-block: calc(var(--size) * 1);
    margin-bottom: calc(var(--size) * 1);
    gap: calc(var(--size) * 2);
    z-index: inherit;
    border-top: 1pt solid #1c212f;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
    &Left {
      z-index: inherit;
      @media only screen and (max-width: 1000px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }
      @media only screen and (min-width: 1001px) {
        display: flex;
        flex-direction: column;
        gap: calc(var(--size) * 2);
      }
    }
    &Right {
      z-index: inherit;
    }
  }

  &Bottom {
    z-index: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(var(--size) * 0.7);
    color: #6b7a97;
    &Left {
      z-index: inherit;
    }
    &Right {
      z-index: inherit;
      display: flex;
      flex-direction: row;
      gap: calc(var(--size) * 1);
      &Link {
        color: #6b7a97;
      }
    }
  }
}
