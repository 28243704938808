@use "../../styles//constants.scss";

.banner {
  position: absolute;
  top: calc(var(--size) * 10);
  left: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  @media only screen and (max-width: 700px) {
    top: calc(var(--size) * 6);
  }
  img {
    width: 100%;
    height: auto;
  }
}
