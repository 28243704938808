:root {
  --size: 14px;
  --page-padding-inline: calc(var(--size) * 1);
  --card-padding-inline: calc(var(--size) * 1);
  --div-gap: calc(var(--size) * 1);
  --card-title-font-size: calc(var(--size) * 1.2);
  --card-ele-title-font-size: calc(var(--size) * 0.7);
  --card-ele-value-font-size: calc(var(--size) * 1.15);
  --card-ele-gap: calc(var(--size) * 0.5);

  @media only screen and (max-width: 500px) {
    --size: 12px;
  }
  @media only screen and (min-width: 1000px) {
    --size: 14px;
    --page-padding-inline: calc(var(--size) * 2);
    --div-gap: calc(var(--size) * 1.4);
    --card-title-font-size: calc(var(--size) * 1.2);
    --card-ele-title-font-size: calc(var(--size) * 0.85);
  }
}
