.currentNodeDetails {
  position: relative;
  padding-inline: calc(var(--size) * 2.4);
  @media only screen and (max-width: 1000px) {
    padding-inline: calc(var(--size) * 1);
  }
  &Stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: calc(var(--size) * 1);
    margin-block: calc(var(--size) * 2);
    @media only screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
    }
  }
}
