@import "./constants.scss";

.graphs {
  position: relative;
  &Cards {
    display: grid;
    grid-template-rows: 1fr;
    gap: var(--size);
    margin-block: calc(var(--size) * 1);
    grid-template-columns: 1fr;
    padding-inline: calc(var(--size) * 1);
    @media only screen and (min-width: 800px) and (max-width: 1000px) {
      padding-inline: calc(var(--size) * 1);
      grid-template-columns: repeat(2, 1fr);
      gap: calc(var(--size) * 2);
    }

    @media only screen and (min-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
      padding-inline: calc(var(--size) * 2.4);
      gap: calc(var(--size) * 2);
    }
  }
  &BG {
    &Left {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
      pointer-events: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
    &Right {
      position: absolute;
      top: 50%;
      right: 0;
      width: 100vw;
      height: auto;
      pointer-events: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
    @media only screen and (min-width: 700px) {
      &Left {
        position: absolute;
        top: 50%;
        left: 0;
        width: 50vw;
        height: auto;
        transform: translateY(-50%);
      }
      &Right {
        position: absolute;
        top: 50%;
        right: 0;
        width: 50vw;
        height: auto;
        transform: translateY(-50%);
      }
    }
  }
}
