@use "../../styles/constants.scss";

.card {
  position: relative;
  background: #020711;
  border: 1pt solid #222a38;
  border-radius: calc(var(--size) / 2);
  box-shadow: 6px 6px 11px 0px rgba(0, 0, 0, 0.04);
  &Container {
    z-index: inherit;
    height: 100%;
    padding-inline: 0;
    padding-block: calc(var(--size) * 1.2);
    width: 100%;
  }
}

.graphCard {
  display: grid;
  grid-template-rows: calc(var(--size) * 1.2) auto;
  grid-template-columns: 1fr;
  gap: var(--card-ele-gap);
  padding-inline: var(--card-padding-inline);
  padding-bottom: calc(var(--card-padding-inline) / 4);
  &Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--card-ele-gap);
  }

  &Title {
    font-size: var(--card-title-font-size);
  }

  &Bottom {
    height: calc(var(--size) * 22);
    // max-height: calc(var(--size) * 20);
    max-width: calc(50vw - calc(var(--size) * 5));
    @media only screen and (max-width: 800px) {
      max-width: calc(100vw - calc(var(--size) * 5));
    }
  }
  &Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }
}

.nodeDetails {
  display: grid;
  grid-template-columns: 66.3% auto;
  grid-template-rows: 1fr;
  gap: calc(var(--size) * 1);
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}

.nodeDetailsCard {
  padding-inline: var(--card-padding-inline);
  display: grid;
  grid-template-rows: calc(var(--size) * 4) auto;
  grid-template-columns: 1fr;
  height: calc(var(--size) * 14);
  gap: calc(var(--size) * 1.5);
  @media only screen and (max-width: 700px) {
    grid-template-rows: calc(var(--size) * 6) auto;
    height: calc(var(--size) * 22);
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: inherit;
  }

  .totalEarnings {
    color: #9eb4ec;
  }

  &Title {
    font-size: var(--card-title-font-size);
  }

  &Address {
    font-size: calc(var(--size) * 0.7);
    color: #a2a2aa;
  }

  &Top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: calc(var(--size) * 1);
    }
    &Left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: calc(var(--size) * 0.5);

      &Top {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: calc(var(--size) * 1);
      }
    }
    &Right {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      gap: calc(var(--size) * 1);
      @media only screen and (max-width: 700px) {
        justify-content: space-between;
        width: 100%;
      }
      .type {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0156fc;
        opacity: 1;
        height: calc((var(--size) * 1.5));
        width: calc((var(--size) * 6));
        font-size: calc(var(--size) * 0.85);
      }
    }
  }

  &Bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    align-items: flex-start;
    justify-content: flex-start;
    gap: calc(var(--size) * 1);

    @media only screen and (min-width: 700px) {
      :last-child {
        grid-column: span 2;
      }
    }

    @media only screen and (max-width: 700px) {
      grid-template-columns: 45% 55%;
      grid-template-rows: calc(var(--size) * 4);
      :first-child {
        grid-column: span 2;
      }
    }
  }
  &Detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    // height: calc(var(--size) * 5);
    gap: var(--card-ele-gap);
    &Title {
      font-size: var(--card-ele-title-font-size);
      color: #a2a2aa;
      font-weight: 300;
    }
    &Value {
      width: 100%;
      font-size: var(--card-ele-value-font-size);
      font-weight: 600;
      padding-block: calc(var(--size) * 0.15);

      &Speed {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: calc(var(--card-ele-gap) * 1.5);
        font-weight: 600;
        padding-block: calc(var(--size) * 0.35);
        @media only screen and (max-width: 300px) {
          flex-direction: column;
          gap: calc(var(--card-ele-gap) * 1);
        }

        section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: var(--card-ele-value-font-size);
          gap: calc(var(--card-ele-gap) * 0.5);
          font-weight: 600;
        }
      }
    }
    &Price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--card-ele-gap);
    }
  }
}

.nodeStatsCard {
  padding-inline: var(--card-padding-inline);
  display: flex;
  flex-direction: column;
  gap: calc(var(--card-ele-gap) * 4);
  &Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &Title {
      font-size: var(--card-title-font-size);
    }
  }
  &Bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: calc(var(--size) * 1);

    &Details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: calc(var(--size) * 0.5);
      width: inherit;

      &Title {
        font-size: var(--card-ele-title-font-size);
        color: #a2a2aa;
        font-weight: 300;
      }
      &Value {
        font-size: var(--card-ele-value-font-size);
      }
    }
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.subscriptionPlansCard {
  position: relative;
  z-index: inherit;
  height: calc(var(--size) * 20);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: calc(var(--card-ele-gap) * 2);
  &Top {
    font-size: var(--card-title-font-size);
    padding-inline: var(--card-padding-inline);
  }
  &Bottom {
    overflow-x: auto;
    height: calc(var(--size) * 18);
    width: 100%;
    padding: 0;
    margin: 0;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th,
    td {
      min-width: calc(var(--size) * 14);
      text-align: left;
      padding-left: var(--size);
      font-size: var(--card-ele-title-font-size);
      word-wrap: break-word;
      text-overflow: ellipsis;
      @media only screen and (max-width: 800px) {
        min-width: calc(var(--size) * 12);
      }
    }
    th {
      color: #a2a2a2;
      font-weight: 300;
      padding-block: var(--size);
    }
    td {
      padding-block: calc(var(--size) * 0.75);
    }
    thead {
      position: sticky;
      top: 0;
      background: #191f30;
    }
    tbody {
      tr {
        &:hover {
          background: rgba(25, 31, 48, 0.5);
        }
      }
    }
  }
}

.mapCard {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.nodeHealth {
  border-radius: 500px;
  border: 1px solid #222a38;
  opacity: 0.8;
  background: #191f31;
  box-shadow: 6px 6px 11px 0px rgba(0, 0, 0, 0.04);
  padding-inline: calc(var(--size) * 0.8);
  padding-block: calc(var(--size) * 0.3);

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: calc(var(--size) * 0.5);
  }
  span {
    font-size: calc(var(--size) * 0.7);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &True {
    color: #00ff00;
  }
  &False {
    color: #f00;
  }
}
