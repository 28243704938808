@use "../../styles/constants.scss";

.dropdown {
  // width: calc(var(--size) * 11);
  position: relative;
  select {
    width: 100%;
    vertical-align: middle;
    margin: 0;
    width: 100%;
    outline: none;
    color: #ffffff;
    font-size: var(--card-ele-value-font-size);
    font-weight: 600;
    background: none;
    border: 1pt solid #222a38;
    border-radius: calc(var(--size) * 0.5);
    padding-block: calc(var(--size) * 0.35);
    padding-inline: calc(var(--size) * 0.25);
    option {
      font-weight: 500;
      width: 100%;
      vertical-align: middle;
      margin: 0;
      outline: none;
      color: #ffffff;
      font-size: var(--card-ele-value-font-size);
      background: red;
    }
  }
  .value {
    font-size: var(--card-ele-value-font-size);
    font-weight: 600;
    padding-block: calc(var(--size) * 0.35);
  }
}
