@import "./constants.scss";

body {
  margin: 0;
  font-family: "Poppins", monospace, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", monospace, sans-serif;
  font-style: normal;
  font-size: var(--size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(00, 06, 10, 1);
  color: rgba(255, 255, 255, 1);
  overflow-x: hidden;
}

a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
