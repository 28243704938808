@use "../../styles//constants.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--size);
  padding-inline: var(--page-padding-inline);
  padding-block: calc(var(--size) * 0.5);
  @media only screen and (max-width: 1000px) {
  }
  .hideOnMObile {
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
}
