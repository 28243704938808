@use "../../styles/constants.scss";

.durationButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: calc(var(--size) * 0.4);
}

.durationButton {
  background: #211e28;
  color: #fff;
  outline: none;
  border: none;
  font-size: calc(var(--card-title-font-size) * 0.5);
  font-weight: 500;
  color: #a2a2a2;
  padding-inline: calc(var(--card-ele-gap) * 0.4);
  padding-block: calc(var(--card-ele-gap) * 0.25);

  border-radius: calc(var(--size) * 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  &Active {
    background: #0156fc;
    color: #ffffff;
  }
}
