.loader {
    border: 0.2em solid transparent;
    border-radius: 50%;
    border-top: 0.2em #fafafa;
    border-bottom: 0.2em #fafafa;
    border-style: dotted;
    width: 4em;
    aspect-ratio: 1;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  