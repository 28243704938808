@import "./constants.scss";

.searchbar {
  z-index: inherit;
  position: relative;
  form {
    background: #000917;
    border: 1px solid #222a38;
    border-radius: calc(var(--size) * 25);
    padding-inline: calc(var(--size) * 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    input {
      width: calc(var(--size) * 30);
      max-width: 80vw;
      height: calc(var(--size) * 2.4);
      background: none;
      outline: none;
      border: none;
      color: #ffffff;
      font-size: calc(var(--size) * 1);
    }
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      color: #ffffff;
      text-align: left;
    }
  }
}
