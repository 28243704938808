@import "./constants.scss";

.app {
  position: relative;
}

.pageTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: calc(var(--size) * 2);
  z-index: 12;
  padding-block: calc(var(--size) * 2);
  &Title {
    position: relative;
    text-align: center;
    font-size: calc(var(--size) * 3.2);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;
}
