.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(16px * 21);
  align-items: flex-end;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 1em;
    padding-inline: 0.5em;
    border-radius: 0.2em;
    background: #211e28;
    border: none;
    outline: none;
    min-width: 1.75em;
    height: 1.56em;

    color: #fff;
    font-size: 0.7em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
  }
}
