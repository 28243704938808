@import "./constants.scss";

.randomNodeLoader {
  position: relative;
  button {
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: calc(var(--size) * 1);
    padding-inline: calc(var(--size) * 0.9);
    padding-block: calc(var(--size) * 0.5);
    background: #020711;
    border: 1px solid #222a38;
    border-radius: calc(var(--size) * 0.8);
    color: #ffffff;
    font-weight: 500;
    font-size: calc(var(--size) * 1.2);
  }
}
