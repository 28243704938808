@use "../../styles/constants.scss";

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--size);
  padding-block: calc(var(--size) * 0.5);
}

.community {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-block: calc(var(--size) * 0.5);
  gap: calc(var(--size) * 6);
  @media only screen and (max-width: 700px) {
    gap: calc(var(--size) * 4);
    flex-wrap: wrap;
  }
  .group {
    display: flex;
    flex-direction: column;
    gap: calc(var(--size) * 1.2);
    &Title {
      font-size: calc(var(--size) * 1.2);
    }
    &Links {
      display: flex;
      flex-direction: column;
      gap: calc(var(--size) * 0.8);
      &Link {
        font-size: calc(var(--size) * 0.9);
        color: #b3b3b3;
      }
    }
  }
}
